.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} 
/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
  transform-origin: 50% 50%;
  display: inline-block;
}

 @keyframes App-logo-spin {
  0 {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.Aprobo-logo-spin {
  animation-name: spin;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-duration: 5s;
  -webkit-animation-name: spin;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-duration: 5s;
  width: 100%;
}

@keyframes spin {
  from{
    transform: rotateY(0deg);
    moz-transform: rotateY(0deg);
    ms-transform: rotateY(0deg); 
  }
  to{
    transform: rotateY(360deg);
    moz-transform: rotateY(360deg); 
    ms-transform: rotateY(360deg); 
  }
}
@-webkit-keyframes spin {
  from{-webkit-transform: rotateY(0deg);}
  to{-webkit-transform: rotateY(360deg);}
}

a {color: #000;}


.main-section{
    height: 100vh;
    text-align: center;
    background: #000;
    width: 100%;
}
.main{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.main h1{color: 	#D3D3D3;}
.main-link{color: 	#D3D3D3;margin-right:10px;font-size: 1.5rem;}

.subpage-container {
  padding: 2rem 0 6rem;
}
.subpage-container ul {padding: 0; margin-bottom: 0;}
.subpage-container li {list-style: none;}
.subpage-container a:hover{text-decoration: none;}

/*_________APPLICATIONS_________*/
.applications-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.applications-list li{
    padding: .5rem 0;
}

.applications-list li a{
  color: #000;
  font-weight: 500;
  display: block;
  font-family: 'Teko', sans-serif;
}
.applications-list li a:hover{
  text-decoration: none;
}

.subpage-container .btn-light {
  color: #212529;
  background-color: #CACDD0;
  border-color: #CACDD0;
  border-radius: 0;
  padding: 0.75rem;
  width: 100%;
}

/*_________PRODUCTS_________*/

.products {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.products li{
  width: calc(50% - .5rem);
  margin: .5rem 0;
}

@media (min-width: 992px) {
  .products li{
    width: calc(25% - .5rem);
  }
}
.product-menu-container {
  background: #000;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.product-menu-container .product-menu {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-menu-container li {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    padding: .75rem 0 .3rem;
    border-left: 1px solid #fff;
    align-items: center;
    flex: 1;
    overflow: visible;
    position: relative;
}

.product-menu-container li:first-child {
    border-left: none;
}

.product-menu-container li a{
    text-transform: uppercase;
    color: #FB926E;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    line-height: 1.5;
    font-weight: 500;
    font-size: 1.3rem;
}
.product-menu-container li a:hover{
  text-decoration: none;
}
.product-menu-container li a .fa{
  align-self: center;
  font-size: 1.5rem;
  color: #FB926E;
}

.product-menu-container li a.active, .product-menu-container li a.active .fa {
    color: #fff;
}

.product-menu a {
  font-family: 'Teko', sans-serif;
}
a.active:before {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #000;
  content: '';
  position: absolute; 
  left: 0;
  top: -19%;
  right: 0;
  margin: 0 auto;
}


/*____________Images_________*/
.product-image-container ul, .Application-image-container ul{
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.product-image-container li, .Application-image-container li {
  list-style: none;
  padding-right: 1rem;
  flex: 0 0 auto;
}

.product-image-container img, .Application-image-container img{width:auto; height: 48vh;}

@media (min-width: 768px) {
  .product-image-container img, .Application-image-container img{height: 60vh;}
}

/*________Documents_______*/
.document-list li{
  margin-bottom: 1rem;
}
.document-list .btn.btn-light {
  display: flex;
  justify-content: space-between;
  padding: 0;
  border: 0;
  align-items: center;
  padding-left: 1rem;
  font-family: 'Teko', sans-serif;
}

.document-list .fa {
  align-self: center;
  color: #FB926E;
  background: #000;
  padding: 1rem;
  font-size: 1.5rem;
}

.document-list a{
  padding: .75rem 1rem 0;
  display: block;
}