@import url('https://fonts.googleapis.com/css?family=Teko:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700&display=swap');

body {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a{
  font-size: 1.2rem;
}
p{
  font-size: 1rem;
  line-height: 1.2;
}
.ingress p{
  font-size: 1.5rem;
  font-family: 'Teko', sans-serif;
  line-height: 1;
}

h1, h2, h3, h4, h5, h6{
  font-weight: 500;
  line-height: 1;
  font-family: 'Teko', sans-serif;
}

img{width: 100%;}

@media (min-width: 992px) {
  a{
    font-size: 1.5rem;
  }
  p{
    font-size: 1.2rem;
    line-height: 1.5;
  }
  .ingress p{
    font-size: 1.75rem;
    line-height: 1.2;
  }
}