/* .logo-banner{background: #FB926E} */

.logo-banner{
    background: #000;
    display: flex;
    justify-content: center;
}

.logo-banner .Aprobo-logo-container {
    height: 20vh;
   
   width:100%;
    position: relative;
    top: 0;
    left: 50%;
    transform: translate(-50%, -0%);
    
}
 
.logo-banner .Aprobo-logo {
    height: 20vh;
    pointer-events: none;
    padding: 1rem 0;
    position: relative;
    top: 0;
    left: 50%;
    transform: translate(-50%, -0%);
    
}
.header-banner {
    background: #FB926E;
    padding: 1rem 0;
    display: flex;
    height: 60px;
}
.header-banner .header-title{
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -0%);
}


.header-banner .header-title a:hover{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.4);
}

.Header .language {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
    margin-right: .5rem;
}

.Header .language button {
    background: #000;
    border: none;
    color: #fff;
    border-right: 1px solid #fff;
    font-size: 1.5em;
    font-family: 'Teko', sans-serif;
    z-index: 10000;
}

.Header .language button:last-child{
    border-right: 0;
}